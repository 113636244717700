// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-category-story-js": () => import("./../../../src/templates/category-story.js" /* webpackChunkName: "component---src-templates-category-story-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-index-rattish-js": () => import("./../../../src/templates/index-rattish.js" /* webpackChunkName: "component---src-templates-index-rattish-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-rattish-js": () => import("./../../../src/templates/page-rattish.js" /* webpackChunkName: "component---src-templates-page-rattish-js" */),
  "component---src-templates-settings-contact-banner-js": () => import("./../../../src/templates/settings-contact-banner.js" /* webpackChunkName: "component---src-templates-settings-contact-banner-js" */),
  "component---src-templates-settings-footer-js": () => import("./../../../src/templates/settings-footer.js" /* webpackChunkName: "component---src-templates-settings-footer-js" */),
  "component---src-templates-settings-menu-js": () => import("./../../../src/templates/settings-menu.js" /* webpackChunkName: "component---src-templates-settings-menu-js" */),
  "component---src-templates-settings-rattish-js": () => import("./../../../src/templates/settings-rattish.js" /* webpackChunkName: "component---src-templates-settings-rattish-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */)
}

